// set email verification = 0 & otp to 0
if(jQuery('body').hasClass('page-id-2875')){


sessionStorage.setItem("emailVerified", "0");
sessionStorage.setItem("set", "0");



// Ready the DOM
jQuery(document).ready(function() {

  ////////////// Search Functionality //////////////
  var getCurrentLocation, seachInput, searchResult, dataStringPincode, loadingTimeout,loading, myLat, myLng, detailError, options, onGeoSuccess, onGeoError, getPincode, getLocationResult, formWrapper;
  getCurrentLocation    = document.getElementById('getCurrentLocation');
  seachInput            = document.getElementById("searchInput");
  seachButton           = document.getElementById("searchLocation");
  searchResult          = document.getElementById("mapResult");
  searchResultData      = document.getElementById("resultData");
  formWrapper           = document.getElementById("nf-form-6-cont");

  // Search button click perform actions
  seachButton.addEventListener('click', (e)=>{
      if(seachInput.value < 99999){
          searchResult.innerHTML = '<span class="error">Please enter your pincode or click on use my current location to auto fetch you location<span>';
          e.preventDefault();
      }else{
          getLocationResult();

          e.preventDefault();
      }
  })


  // Get current location
  options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
  };

  getCurrentLocation.addEventListener("click", getLocation);

  // Getting GEO coding
  function getLocation() {
      let geolocation = navigator.geolocation;
      if (geolocation) {
          searchResult.innerHTML = '<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 20" enable-background="new 0 0 0 0" xml:space="preserve"> <circle fill="#f4bc40" stroke="none" cx="6" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/></circle><circle fill="#f4bc40" stroke="none" cx="26" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/></circle> <circle fill="#f4bc40" stroke="none" cx="46" cy="10" r="6"> <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/></circle> </svg>';
          geolocation.getCurrentPosition(onGeoSuccess, onGeoError, options);
      } else {
          console.log("Geolocation is not supported by this browser.");
      }
  }

  onGeoSuccess = (position)=> {
      myLat = position.coords.latitude;
      myLng = position.coords.longitude;
      if(myLat == '' || myLat == ''){}

      else{
          getPincode();
      }
  }

  // GEO coding error management
  onGeoError = (error) => {
      if(error.code === error.PERMISSION_DENIED) {
      detailError = "Permission denied!";
      }
      else if(error.code === error.POSITION_UNAVAILABLE) {
      detailError = "Location information is unavailable!";
      }
      else if(error.code === error.TIMEOUT) {
      detailError = "Request timeout!"
      }
      else if(error.code === error.UNKNOWN_ERROR) {
      detailError = "An unknown error occurred!"
      }
      searchResult.innerHTML = detailError;
  }

  // Getting pincode getMyCurrentLocation
  getPincode = ()=> {
      jQuery.getJSON( `https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?prox=${myLat},${myLng}&mode=retrieveAddresses&maxresults=1&gen=9&apiKey=2G9XhwUEYrJRIsQjD_5QKHnqm4V3JAEzcmDJjI67g5Q`, function(data) {
          seachInput.value = data.Response.View[0].Result[0].Location.Address.PostalCode;
          searchResult.innerHTML = 'Finding MTP Providers....';
          getLocationResult();
      })
      .done( function() {
          console.log('done');
      }).fail( function() {
          console.log('fail');
      }).always( function() {
      console.log('always');
      });
  }

  // Getting nearby result data from MYsql
  getLocationResult = ()=> {
      searchResult.innerHTML = '<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 20" enable-background="new 0 0 0 0" xml:space="preserve"> <circle fill="#f4bc40" stroke="none" cx="6" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/></circle><circle fill="#f4bc40" stroke="none" cx="26" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/></circle> <circle fill="#f4bc40" stroke="none" cx="46" cy="10" r="6"> <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/></circle> </svg>';
      jQuery.getJSON( `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=2G9XhwUEYrJRIsQjD_5QKHnqm4V3JAEzcmDJjI67g5Q&searchtext=${seachInput.value}`, function(data) {
          lat                     = data.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
          long                    = data.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
          pincode                 = seachInput.value;
          dataStringLocation      = 'lat='+ lat + '&long=' + long + '&pincode=' + pincode;
          lat_long                = lat + ',' + long;
          jQuery('#nf-field-77').val(lat_long).trigger('change');
          jQuery.ajax({
              type: "POST",
              url: "https://"+window.location.hostname+"/wp-content/themes/pratigya/sendOtpModule/locationdata.php",
              data: dataStringLocation,
              success: function(data) {
                response = jQuery.parseJSON(data);
                if(response.count === ''){
                  searchResult.innerHTML = '';
                  // searchResult.innerHTML = 'hello' + response.count;
                  searchResult.innerHTML = '<span class="error">Sorry!  Currently, we are unable to find any MTP provider near you </span> <br/>However, we are constantly updating our database. Kindly visit us again in a few days' ;
                  jQuery('.detail-form-wrapper').delay(1000).fadeOut(1000);

                  gtag('event', 'search', {'event_category': 'MTP Provider','event_label': 'Fail'});

                }else{
                  searchResult.innerHTML = '';
                  searchResult.innerHTML = '<span class="success">Successful! ' + response.count +' MTP providers found near you</span><br/>Fill the following form and we will provide you with further information on these providers<br/><br/>  <span class="success">आपके आसपास ' + response.count +' MTP प्रदाता हैं।</span> <br/>नीचे दिए गए फॉर्म को भरें और हम आपको इन प्रदाताओं के बारे में अधिक जानकारी प्रदान करेंगे।';

                  jQuery('.detail-form-wrapper').delay(1000).fadeIn(1000);
                  // jQuery( '#nf-field-78' ).val( response.result ).trigger( 'change' );
                  // searchResultData.innerHTML = '';
                  // searchResultData.innerHTML = response.result;
                  // jQuery('.result-showcase-wrapper').delay(1000).fadeIn(1000);
                  gtag('event', 'search', {'event_category': 'MTP Provider','event_label': 'Success'});
                }
              }
            });

      })
      .done( function() {
          console.log('done');
      }).fail( function() {
          console.log('fail');
      }).always( function() {
      console.log('always');
      });
  }


  // Allow only numbers in OTP field
  jQuery(seachInput).keypress((event)=>{
      if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
          event.preventDefault();
      }
  });
    // Readt the ninja forms
    jQuery(document).on("nfFormReady", function() {

        // if( jQuery(".detail-form-wrapper").css('display') == 'block') {

        // All variables
        var submitButton, emailField, sendOtp, otpField, emailValidation, otpString, otptime, response, otpFieldError, emailFieldError, interval, formError, formID, searchForm, otpForm, otpOnValue;


        init();

            function init(){



                emailField              =  document.getElementById('nf-field-66');                  // email field ID
                emailFieldError         =  document.getElementById('nf-error-70');                  // email field error ID

                otpField                =  document.getElementById('nf-field-69');                  // OTP field ID
                otpFieldError           =  document.getElementById('nf-error-69');                  // OTP field error ID

                phoneField              =  document.getElementById('nf-field-67');                  // phone field ID
                phoneFieldError         =  document.getElementById('nf-error-67');                  // phone field error ID

                cityField               =  document.getElementById('nf-field-71');                  // phone field ID
                cityFieldError          =  document.getElementById('nf-error-71');                  // phone field error ID

                stateField              =  document.getElementById('nf-field-73');                  // phone field ID
                stateFieldError         =  document.getElementById('nf-error-73');                  // phone field error ID

                pincodeField            =  document.getElementById('nf-field-72');                  // phone field ID
                pincodeFieldError       =  document.getElementById('nf-error-72');                  // phone field error ID

                countryField            =  document.getElementById('nf-field-74');                  // phone field ID
                countryFieldError       =  document.getElementById('nf-error-74');                  // phone field error ID

                messageField            =  document.getElementById('nf-field-75');                  // message field ID
                messageError            =  document.getElementById('nf-error-76');                  // message field error ID

                submitButton            =  document.getElementById('nf-field-80');                  // submit button ID

                sendOtp                 =  document.getElementById('send_otp_button');              // send otp button ID

                emailValidation         =  document.getElementById('nf-field-66-wrap');             // email wrapper to find is this has nf-pass class
                otptime                 =  document.getElementById('otptime');                      // where you want to show timer ID
                formError               =  document.getElementById('nf-form-errors-6');             // entire form error wrapper
                formID                  =  6;
                otpWrap                 =  document.getElementById('nf-field-69-wrap');             // email wrapper to find is this has nf-pass class

                searchForm              =  document.getElementById('searchForm');                   // search form wrapper
                // otpWotpFormrap       =  document.getElementById('nf-form-6-cont');            // otp form wrapper

                searched_pincode        = document.getElementById('nf-field-76');
                searchedResults         = document.getElementById('searchedResults');

                submitButton.disabled   = true;
                searchForm.autocomplete = "off";
                // otpForm.autocomplete    = "off";

        }



        otpField.addEventListener('focusout', ()=>{
            anyFieldFocusOUt();
        });


        cityField.addEventListener('focusout', ()=>{
            anyFieldFocusOUt();
        });

        stateField.addEventListener('focusout', ()=>{
            anyFieldFocusOUt();
        });

        pincodeField.addEventListener('focusout', ()=>{
            anyFieldFocusOUt();
        });

        countryField.addEventListener('focusout', ()=>{
            anyFieldFocusOUt();
        });
        messageField.addEventListener('focusout', ()=>{
            anyFieldFocusOUt();
        });

        sendOtp

        // on any field focus out disable submit button
        function anyFieldFocusOUt(){
            jQuery('#nf-field-76').val(seachInput.value).trigger('change');

            if(sessionStorage.getItem('emailVerified') == 0){
                submitButton.disabled = true;

                // otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error">Please enter a valid OTP</div>';
                otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error">Please enter a valid OTP</div>';
            }else{
                submitButton.disabled = false;
            }
        }

        // Allow only numbers in OTP field
        jQuery(otpField).keypress((event)=>{
            if(event.which != 8 && isNaN(String.fromCharCode(event.which))){
                event.preventDefault();
            }
        });

        // on every keyup check otp
        otpField.addEventListener('keyup change',()=>{
            if(sessionStorage.getItem('otp') === otpField.value){
                sessionStorage.setItem("emailVerified", "1");
                otpFieldError.innerHTML = '';
                // otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error" style="color:green" id="otpVerified">Thank you for verifying your email address</div>';
                emailFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error" style="color:green" id="otpVerified">Thank you for verifying your email address and mobile number</div>';
                // jQuery('#otpVerified').fadeIn('fast').delay(2000).fadeOut('fast');
                jQuery('#otpVerified').fadeIn('fast');
                sendOtp.value = "Email Address/Mobile No Verified";
                sendOtp.disabled = true;
            }
        });


        // OTP field foucus out
        jQuery(otpField).focusout(function(){
           if(otpField.value == ''){

           }else{
                if(sessionStorage.getItem("set")==0){
                // otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error">Please enter a valid OTP</div>';
                otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error">Please enter a valid OTP</div>';
                }else{
                    if(sessionStorage.getItem("set") == otpField.value){

                        sessionStorage.setItem("emailVerified", "1");
                        otpField.readOnly = true;

                        jQuery('#nf-field-66').attr('readonly', true);
                        jQuery('#nf-field-67').attr('readonly', true);
                        jQuery('input[name="nf-field-82"]').attr('disabled',true);
                        otpFieldError.innerHTML = '';
                        // otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error" style="color:green" id="otpVerified">Thank you for verifying your email address</div>';
                        emailFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error" style="color:green" id="otpVerified">Thank you for verifying your email address/mobile number.</div>';
                        // jQuery('#otpVerified').fadeIn('fast').delay(10000).fadeOut('fast');
                        jQuery('#otpVerified').fadeIn('fast');
                        sendOtp.value = "Email Address/Mobile No. Verified";
                        sendOtp.disabled = true;


                    }else{
                        // otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error">Please enter a valid OTP</div>';
                        otpFieldError.innerHTML = '<div class="nf-error-msg nf-error-required-error">Please enter a valid OTP</div>';
                    }
                }
            }
        });

        otpOnValue = jQuery( 'input[name="nf-field-82"]:checked' ).val();
        jQuery('input[name="nf-field-82"]').change(function(){
          otpOnValue = jQuery( 'input[name="nf-field-82"]:checked' ).val();

        });

         // On Click sendotp button send otp and show very otp box
         sendOtp.addEventListener('click', ()=>{

           // GA event for OTP sent
           gtag('event', 'Send OTP', {'event_category': 'MTP Provider','event_label': 'Success'});
           jQuery('#nf-field-66').attr('readonly', true);
           jQuery('#nf-field-67').attr('readonly', true);
           jQuery('input[name="nf-field-82"]').attr('disabled',true);
           sendOtp.disabled = true;
           otpFieldError.innerHTML = '';


            // send OTP on email
           if(jQuery('#nf-field-66').length > 0){


             if(jQuery('#nf-field-66').val() === '' || jQuery('#nf-field-66').val() === undefined){
               emailFieldError.innerHTML = '<div class="nf-error-msg nf-error-invalid-email empty-email-error">Please enter a valid email address!</div>';
               jQuery('#nf-field-66').attr('readonly', false);
               jQuery('#nf-field-67').attr('readonly', false);
               jQuery('input[name="nf-field-82"]').attr('disabled',false);
               sendOtp.disabled = false;
             }else{
                 if(jQuery('#nf-field-66-wrap').hasClass("nf-fail")){
                   emailFieldError.innerHTML = '<div class="nf-error-msg nf-error-invalid-email empty-email-error">Something Went Wrong Try Again!</div>';

                 }else{

                     jQuery(document).ready(function($) {
                         otpString = 'email='+ jQuery('#nf-field-66').val() + '&otpOn='+ otpOnValue;

                         // otpString = 'email='+ emailField.value + '&mobile_no='+ phoneField.value;
                         emailFieldError.innerHTML = '<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 20" enable-background="new 0 0 0 0" xml:space="preserve"> <circle fill="#f4bc40" stroke="none" cx="6" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/></circle><circle fill="#f4bc40" stroke="none" cx="26" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/></circle> <circle fill="#f4bc40" stroke="none" cx="46" cy="10" r="6"> <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/></circle> </svg>';
                         $.ajax({
                             type: "POST",
                             url: "https://"+window.location.hostname+"/wp-content/themes/pratigya/sendOtpModule/sendotp.php",
                             data: otpString,
                             success: function(data) {
                                 response = jQuery.parseJSON (data);
                                 if(response.response === 'success'){

                                     sessionStorage.setItem("set", response.set);
                                     emailFieldError.innerHTML = '';
                                     emailFieldError.innerHTML = 'Sent OTP on your email, please check.';

                                     setTimeout(function(){
                                         emailFieldError.innerHTML = '&nbsp;';
                                         emailFieldError.innerHTML = 'Sent OTP on your email, please check. It will expire in <span id="otptime"></span> mins';
                                         sendOtp.value = "Resend OTP";
                                         jQuery('#otptime').text("4:00");

                                         countdown();
                                     },1000);

                                 }else if(response.response === 'error'){
                                     sessionStorage.setItem("otp", response.set);
                                 }else if(response.response === 'error_occured'){
                                     sessionStorage.setItem("otp", response.set);
                                     emailFieldError.innerHTML = '';
                                     emailFieldError.innerHTML = 'Something went wrong! Try again!';
                                 }else{
                                     sessionStorage.setItem("otp", 0);
                                 }
                             }
                         });
                     });
                 }
              }

           }


           // send OTP on mobile_no
           if(jQuery('#nf-field-67').length > 0){

             var phoneno = /^[0]?[789]\d{9}$/;


             if(jQuery('#nf-field-67').val() === '' || jQuery('#nf-field-67').val() === undefined){
               emailFieldError.innerHTML = '<div class="nf-error-msg nf-error-invalid-email empty-email-error">Please enter a valid mobile number!</div>';
               jQuery('#nf-field-66').attr('readonly', false);
               jQuery('#nf-field-67').attr('readonly', false);
               jQuery('input[name="nf-field-82"]').attr('disabled',false);
               sendOtp.disabled = false;
             }else{
                 if(jQuery('#nf-field-67-wrap').hasClass("nf-fail")){
                   emailFieldError.innerHTML = '<div class="nf-error-msg nf-error-invalid-email empty-email-error">Something Went Wrong Try Again!</div>';
                   jQuery('#nf-field-66').attr('readonly', false);
                   jQuery('#nf-field-67').attr('readonly', false);
                   jQuery('input[name="nf-field-82"]').attr('disabled',false);
                   sendOtp.disabled = false;
                 }else{

                   if(jQuery('#nf-field-67').val().match(phoneno)){

                     jQuery(document).ready(function($) {


                        otpString = 'mobile_no='+ jQuery('#nf-field-67').val() + '&otpOn='+ otpOnValue;
                         // otpString = 'email='+ emailField.value + '&mobile_no='+ phoneField.value;
                         emailFieldError.innerHTML = '<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 20" enable-background="new 0 0 0 0" xml:space="preserve"> <circle fill="#f4bc40" stroke="none" cx="6" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"/></circle><circle fill="#f4bc40" stroke="none" cx="26" cy="10" r="6"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"/></circle> <circle fill="#f4bc40" stroke="none" cx="46" cy="10" r="6"> <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"/></circle> </svg>';
                         $.ajax({
                             type: "POST",
                             url: "https://"+window.location.hostname+"/wp-content/themes/pratigya/sendOtpModule/sendotp.php",
                             data: otpString,
                             success: function(data) {
                                 response = jQuery.parseJSON (data);
                                 if(response.response === 'success'){

                                     sessionStorage.setItem("set", response.set);
                                     emailFieldError.innerHTML = '';
                                     emailFieldError.innerHTML = 'Sent OTP on your mobile number, please check.';

                                     setTimeout(function(){
                                         emailFieldError.innerHTML = '&nbsp;';
                                         emailFieldError.innerHTML = 'Sent OTP on your mobile number, please check. It will expire in <span id="otptime"></span> mins';
                                         sendOtp.value = "Resend OTP";

                                         jQuery('#otptime').text("4:00");
                                         countdown();
                                     },1000);

                                 }else if(response.response === 'error'){
                                     sessionStorage.setItem("otp", response.set);
                                 }else if(response.response === 'error_occured'){
                                     sessionStorage.setItem("otp", response.set);
                                     emailFieldError.innerHTML = '';
                                     emailFieldError.innerHTML = 'Something went wrong! Try again!';
                                 }else{
                                     sessionStorage.setItem("otp", 0);
                                 }
                             }
                         });
                     });

                   }else{

                     emailFieldError.innerHTML = '<div class="nf-error-msg nf-error-invalid-email empty-email-error">Please enter a valid Mobile Number!</div>';
                     jQuery('#nf-field-66').attr('readonly', false);
                     jQuery('#nf-field-67').attr('readonly', false);
                     jQuery('input[name="nf-field-82"]').attr('disabled',false);
                     sendOtp.disabled = false;

                   }
                 }
              }

           }

        });




         // Timer function
         function countdown() {
            clearInterval(interval);
            interval = setInterval( function() {
                var timer = jQuery('#otptime').html();
                timer = timer.split(':');
                var minutes = timer[0];
                var seconds = timer[1];
                seconds -= 1;
                if (minutes < 0) return;
                else if (seconds < 0 && minutes != 0) {
                    minutes -= 1;
                    seconds = 59;
                }
                else if (seconds < 10 && length.seconds != 2) seconds = '0' + seconds;
                jQuery('#otptime').html(minutes + ':' + seconds);
                if (minutes == 0 && seconds == 0) {
                    clearInterval(interval);
                    //after 2 minutes timer enable the button again
                    if(sessionStorage.getItem('emailVerified') == 0){
                      sendOtp.disabled = false;
                      jQuery('#nf-field-66').attr('readonly', false);
                      jQuery('#nf-field-67').attr('readonly', false);
                      jQuery('input[name="nf-field-82"]').attr('disabled',false);

                    }
                    emailFieldError.innerHTML = '';
                    otpFieldError.innerHTML = '';
                }
            }, 1000);
            }
        // }
    });
});

jQuery(document).on( 'nfFormSubmitResponse', function() {
  gtag('event', 'Form Submission', {'event_category': 'MTP Provider','event_label': 'Success'});
  jQuery('.search-wrapper').delay(0).fadeOut(1000);
  jQuery('.detail-form-wrapper').delay(0).fadeOut(1000);
  searchResultData.innerHTML = '';
  searchResultData.innerHTML = response.result;
  jQuery('.result-showcase-wrapper').delay(1000).fadeIn(1000);
});


}
